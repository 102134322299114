/* THE FOUR STEPS TO BEING AN AWESOME UI ENGINEER (AND CO-CODER)
1. Try to keep custom styles to a minimum.
2. Be aware that these styles will affect your entire application.
3. Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname.
4. Use Canvas global variables for color, spacing and more.
They are CSS variables that are already globally available in the browser.
The full list is located here: https://canvas.prod.target.com/design-tokens. */

.praxcss-height100vh {
  height: 100vh;
}

.max-height13vh ul{
  max-height: 13vh;
}

.praxcss-avatarButton {
  border: 0;
  padding: 0;
  border-radius: var(--canvas-border-radius--expanded);
}

.C-Button {
  border-radius: var(--canvas-border-radius--min);
  background: transparent;

  &.\--type-destructive {
    background: var(--canvas-background-color--red);
    color: var(--canvas-global-color--white);
    border-color: var(--canvas-background-color--red);

    &:hover {
      background: var(--canvas-background-color--default-active);
      color: var(--canvas-global-color--default);
      border-color: var(--canvas-background-color--default-hover);
    }
  }
}

.footer {
  background-color: #fff;
  position: fixed;
  width: 45%;
  bottom: 0;
  right: 0;
  margin-right: 3%;
  z-index: 100;
 }

 .C-Card {
  background: var(--canvas-background-color--card);
  border-radius: var(--canvas-border-radius--normal);
}

.viewScreenSplit {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
}

.viewScreenAttachment {
  left: 0;
}
.viewScreenInfo {
  right: 0;
  padding-bottom: 120px
}

.top-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
}


.bulkCloseButton {
  position: absolute;
  right: 1px;
  top: 1px;
}

.bulkCard {
  position: relative;
}

.autocomplete-custom {
  margin-top: -4px;
  height: 32px;
}
.margin-top-20 {
  margin-top: 20px;
}
